import React from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import SanitizedHTML from '../../components/SanitizedHTML/SanitizedHTML';
import {useEcommerce} from '../../contexts/EcommerceContext';

import styles from './StoreWhoWeAre.module.scss';

const WhoWeAre = () => {
  const [state] = useEcommerce();
  const {t} = useTranslation('pages');
  const title = state.settings.COMPANY_STORE_INFO_TITLE
    ? state.settings.COMPANY_STORE_INFO_TITLE.charAt(0).toUpperCase() +
      state.settings.COMPANY_STORE_INFO_TITLE.slice(1).toLowerCase()
    : t('StorePage.StoreNavbar.whoWeAre');

  return (
    <Container fluid className="mt-2 px-1 px-lg-4">
      <Row className="gx-1">
        <Col xs={12} className={styles.whoWeAre}>
          <h1>{title}</h1>
          <div>
            <SanitizedHTML dirtyHTML={state.settings.COMPANY_WHO_WE_ARE} />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default WhoWeAre;
